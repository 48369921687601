






















































import Confirm from '@/components/core/Confirm.vue';
import WelcomeMixin from '@/mixins/welcome-mixin';
import UserServices from '@/services/user-services';
import { Component, Vue } from 'vue-property-decorator';

@Component({
  mixins: [WelcomeMixin]
})
export default class Login extends Vue {
    valid = false;
    resetPasswordEmail = '';
    loading!: boolean;
    requiredRule!: Array<any>;
    emailRules!: Array<any>;

    $refs!: {
      confirm: Confirm,
      resetForm: Vue & { validate: () => boolean, resetValidation: () => void }
    }

    resetPassword() {
      if (this.$refs.resetForm.validate()) {
        this.loading = true;
        UserServices.resetPassword({ email: this.resetPasswordEmail }).then(async () => {
          this.loading = false;
          return this.$refs.confirm.alert({
            title: this.$t('resetPasswordLabel'),
            text: this.$t('passwordResetMsg', { emailAddress: this.resetPasswordEmail }),
            option1ButtonAlternativeText: this.$t('okLabel')
          }).then(() => {
            return this.$router.push({ path: '/auth/login' });
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    }
}
