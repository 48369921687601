import { namespace } from 'vuex-class';
import { Component, Vue } from 'vue-property-decorator';
import CommonUtils from '@/utils/common-utils';
import { emailFormat } from '@/constants';

const settings = namespace('settings');
const welcome = namespace('welcome');

@Component
export default class WelcomeMixin extends Vue {
  @settings.Action
  signIn!: (user: any) => Promise<any>;

  @settings.Action
  signUp!: (user: any) => Promise<any>;

  @welcome.Action
  loadJoinData!: () => Promise<any>;

  @welcome.State
  joinData!: any;

  @welcome.State
  studentJoinData!: any;

  @welcome.Mutation
  reset!: () => void;

  infoMsg = '';
  errorMsg = '';
  loading = false;
  hasPasswordValidationMsg = false;

  get redirected() {
    return this.$store.state.welcome.redirected;
  }

  set redirected(value: boolean) {
    this.$store.commit('welcome/setRedirected', value);
  }

  get joinCode() {
    return this.$store.state.welcome.joinCode || '';
  }

  set joinCode(value: string) {
    this.$store.commit('welcome/setJoinCode', value);
  }

  get regToken() {
    return this.$store.state.welcome.regToken || '';
  }

  set regToken(value: string) {
    this.$store.commit('welcome/setRegToken', value);
  }

  get authRedirectUri() {
    return this.$store.state.welcome.authRedirectUri || '';
  }

  set authRedirectUri(value: string) {
    this.$store.commit('welcome/setAuthRedirectUri', value);
  }

  get userType() {
    return this.$store.state.welcome.userType || 'T';
  }

  set userType(value: string) {
    this.$store.commit('welcome/setUserType', value);
  }

  get promoCode() {
    return this.$store.state.welcome.promoCode || '';
  }

  set promoCode(value: string) {
    this.$store.commit('welcome/setPromoCode', value);
  }

  get resetCode() {
    return this.$store.state.welcome.resetCode || '';
  }

  set resetCode(value: string) {
    this.$store.commit('welcome/setResetCode', value);
  }

  get joinTokenData() {
    return (
      CommonUtils.getTokenData(this.joinCode) || {
        schoolId: 0,
        accountExists: false
      }
    );
  }

  get localStudentJoinData() {
    return (this.studentJoinData || {
      subjectIds: [],
      teacherId: 0,
      schoolId: 0,
      allowNewStudentAccounts: false
    });
  }

  get accountExists() {
    return (this.joinData || { accountExists: false }).accountExists;
  }

  get allowNewStudentAccounts() {
    return (this.studentJoinData || { allowNewStudentAccounts: false }).allowNewStudentAccounts;
  }

  get isValidStudentJoinCode() {
    return CommonUtils.isNotEmpty(this.studentJoinSubjectIds);
  }

  get studentJoinSubjectIds() {
    return (this.studentJoinData || { subjectIds: [] }).subjectIds
  }

  get joinTokenSchoolId() {
    return this.joinTokenData.schoolId;
  }

  get studentJoinCode() {
    return this.$store.state.welcome.studentJoinCode || '';
  }

  set studentJoinCode(value: string) {
    this.$store.commit('welcome/setStudentJoinCode', value);
  }

  get requiredRule() {
    return [(v: any) => !!v || this.$t('requiredLabel')];
  }

  get emailRules() {
    return this.requiredRule.concat(this.emailFormatRule);
  }

  get emailFormatRule() {
    return [(v: string) => CommonUtils.hasNoText(v) || emailFormat.test(v) || this.$t('emailMustBeValidMsg')];
  }

  get hasErrorMsg() {
    return CommonUtils.hasText(this.errorMsg);
  }

  get hasInfoMsg() {
    return CommonUtils.hasText(this.infoMsg);
  }

  get hasStudentJoinCode() {
    return CommonUtils.hasText(this.studentJoinCode);
  }

  get hasJoinCode() {
    return CommonUtils.hasText(this.joinCode);
  }

  get hasRegToken() {
    return CommonUtils.hasText(this.regToken);
  }

  get teacherId() {
    if (this.hasStudentJoinCode) {
      return this.localStudentJoinData.teacherId;
    }
  }

  get yearId() {
    if (this.hasStudentJoinCode) {
      return this.localStudentJoinData.yearId;
    }
  }

  get schoolId() {
    if (this.hasStudentJoinCode) {
      return this.localStudentJoinData.schoolId;
    } else if (CommonUtils.hasText(this.joinCode)) {
      return this.joinTokenSchoolId;
    }
  }

  get subjectIds() {
    if (this.hasStudentJoinCode) {
      return this.localStudentJoinData.subjectIds;
    }
  }

  fru(condition: boolean, value: any) {
    return condition ? value : undefined;
  }

  doReset() {
    this.reset();
    this.infoMsg = '';
  }
}
